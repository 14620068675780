import { rstr_sha256, rstr_hmac_sha256, rstr2b64, b64_sha256 } from './sha256';

import { b64_md5 } from './md5';

const toMd5Hash = ({ user = '', pass, token }) => {
  const ul = user.toLowerCase();
  return b64_sha256(`${ul}:${b64_md5(pass)}:${token}`);
};

const toHmacHash = ({ user = '', pass, token, nonce, userSalt }) => {
  const ul = user.toLowerCase();
  const sha = rstr_sha256(`${pass}${userSalt}`);
  const mac = rstr_hmac_sha256(sha, ul);
  const b64 = rstr2b64(mac);

  return b64_sha256(`${ul}:${b64}:${token},${nonce}`);
};

const HashAlgorithms = {
  MD5: toMd5Hash,
  HMAC: toHmacHash,
};

const toHash = ({ algorithm, user = '', pass, token, nonce, userSalt }) => {
  const f = HashAlgorithms[algorithm] || toHmacHash;
  return f({ user, pass, token, nonce, userSalt });
};

export const toAuth = ({
  algorithm = 'MD5',
  userSalt,
  token,
  nonce,
  pass,
  user,
  sessionID,
}) => {
  const hash = toHash({ algorithm, userSalt, token, nonce, pass, user });
  return `${hash} ${user} ${sessionID}`;
};
