//This isn't great but we are missing symetry between the auth0 and xnet client, so working on it
const redirectFn = (url) =>
  window.location.assign(`${url || '/'}${window.location.search}`);
const reloadFn = () => window.location.reload(true);

export const toXnetAuthClient = (
  user,
  authorization = '',
  followRedirect = true
) => {
  //As we can get auth from local storage, self-auth and other sources, normalize
  //it here and we will reapply the XNet token if applicable when requests are made
  let cachedAuth = authorization.replace('XNet ', '');

  const client = {
    getTokenSilently: () => {
      //This basically ensures that any request in flight don't try to use the old auth token
      //immediately upon logout
      return cachedAuth
        ? Promise.resolve(cachedAuth)
        : new Promise(
            () => {},
            () => {}
          );
    },
    getUser: () => {
      console.warn('Come back to me, this should be a profile');
      return Promise.resolve(user);
    },
    isAuthenticated: () => {
      return Promise.resolve(!!cachedAuth);
    },
    logout: (config) => {
      const { returnTo } = config;

      cachedAuth = undefined;

      return Promise.resolve(undefined).then(() => {
        if (followRedirect) {
          if (returnTo) {
            redirectFn(returnTo);
          } else {
            reloadFn();
          }
        }

        return true;
      });
    },
  };

  return client;
};

export default toXnetAuthClient;
