import { init as initApm } from '@elastic/apm-rum';
import { b64_sha256 } from 'xnetjs/auth/sha256';

const toCompatibleElasticOriginUrl = (url = '') => {
  const urlEndIndex = url.lastIndexOf('/Synergy');
  return url.slice(0, urlEndIndex);
};

const getPluginNamesString = () => {
  const { navigator: { plugins = {} } = {} } = window || {};
  const pluginObjectList = Object.values(plugins);
  const pluginNameList = pluginObjectList.map(({ name }) => name);
  return pluginNameList.join(', ');
};

export const buildRUMLabels = () => {
  const { navigator: { userAgent } = {} } = window || {};
  const pluginNamesString = getPluginNamesString();
  const sessionStartTime = Date.now().toString();

  const deviceId = b64_sha256(`${userAgent}${pluginNamesString}`);
  const sessionId = b64_sha256(`${deviceId}${sessionStartTime}`);

  return {
    deviceId,
    sessionId,
  };
};

const toLikelyEnvironment = (host = '') => {
  if (host.includes('thazdev') || host.includes('localhost')) {
    return 'development';
  } else if (host.includes('thazqa')) {
    return 'QA';
  } else {
    return 'production';
  }
};

const toRumProvider = (window) => {
  const [body] = window.document.getElementsByTagName('body') || [];
  const { dataset: { rumProvider = 'none' } = {} } = body;

  return rumProvider;
};

export const registerRumSideEffects = (
  serverConfig = {},
  labels = {},
  specifiedEnvironment,
  serviceVersion = '0.0.1'
) => {
  const { location: { host = '' } = {} } = window || {};
  const { pathRoot = '' } = serverConfig;

  const rumProvider = toRumProvider(window);

  if (rumProvider !== 'elastic') return;

  const environment = specifiedEnvironment ?? toLikelyEnvironment(host);

  const elasticOriginUrl = toCompatibleElasticOriginUrl(pathRoot);
  const distributedTracingOrigins = elasticOriginUrl ? [elasticOriginUrl] : [];

  const apm = initApm({
    serviceName: 'syntranet client',

    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl: 'https://thaz-elastic-rum.thrasys.com/',

    // Set service version (required for sourcemap feature)
    serviceVersion,
    environment,
    apmRequest: (x) => {
      const { xhr } = x;
      xhr.setRequestHeader(
        'xe-appid',
        'b7c209e80c4674603447458e62963ed5246b5297'
      );
      return true;
    },
    propagateTracestate: true,
    distributedTracingOrigins,
  });

  if (Object.keys(labels).length) {
    apm.addLabels(labels);
  }
};

export default registerRumSideEffects;
